<template>
  <div class="pageCenter">
    <el-backtop></el-backtop>
    <img src="../../assets/image/新闻资讯/资讯中部广告.png" alt="" />
    <div class="info-content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/newsConsult' }"
          >返回 |
        </el-breadcrumb-item>
      </el-breadcrumb>
      <h1>企业资讯</h1>
      <h2>武汉轩迪科技有限公司2021年正式启航</h2>
      <p class="intro">
        武汉轩迪科技有限公司，成立与2021年3月。公司团队在公共交通领域沉淀26年，具有丰富的行业经验与客户资源。
      </p>
      <p class="intro">
        武汉轩迪是一家创新型的科技公司，本公司依托物联网、大数据、人工智能等数字化技术，遵循”智慧交通“”便捷公交“”绿色公交“的行业发展理念，对接公共交通各类资源，打通公共交通营运、安全能耗、线上线下融合、支付等相关数据节点，构建公共交通大数据系统融合决策平台，为公共交通行业提供数字化决策服务，助力公共交通行业达到降本增效、智慧运营的目的
      </p>
      <img src="../../assets/image/新闻资讯/VCG211317949995.png" alt="" />
      <p class="intro">
        公司坐落在繁华的武汉楚河汉街--汉街国际总部A座1607，公司研发团队沉淀3年之久，在大数据领域有着丰富
        的研发经验与能力。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    goBack() {
      this.$router.push('/newsConsult')
    }
  }
}
</script>
<style scoped lang="less">
.el-breadcrumb {
  display: inline-block;
}
h1 {
  display: inline-block;
  font-size: 40px;
  color: #333;
  margin: 0 0 50px 30px;
  border-bottom: 2px solid #3468d2;
}
img{
  width: 100%;
}
h2 {
  margin-top: 0 64px;
  font-size: 42px;
  color: #333;
}
.info-content {
  margin: 82px 160px 60px;
  img {
  margin: 20px 150px;
  width: 1305px;
  }
}
.content-center {
  text-align: center;
  margin: 20px;
}
.el-page-header {
  width: 200px;
  margin: 50px 0;
}
.el-page-header__content::after {
  content: '';
  display: block;
  width: 4%;
  height: 2px;
  margin-top: 8px;
  background-color: red;
}
.intro {
  margin-top: 20px;
  font-size: 30px;
  line-height: 72px;
  color: #333;
  text-indent: 2em;
}
.main-point {
  line-height: 250%;
  margin-top: 20px;
}
</style>
